import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PaymentMethodsEffects } from './+state/payment-methods.effects';
import { PaymentMethodsFeature } from './+state/payment-methods.feature';

@NgModule({
    imports: [StoreModule.forFeature(PaymentMethodsFeature), EffectsModule.forFeature(PaymentMethodsEffects)],
})
export class PaymentMethodsModule {}
