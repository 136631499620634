import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslatePoHttpLoader } from '@fjnr/ngx-translate-po-http-loader';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { provideAnalytics, TagManagerMockService, TagManagerService } from '@slm/analytics';
import { EpjSearchModule } from '@slm/epj-search';
import { provideLocationState } from '@slm/location/state';
import { provideOnBehalf } from '@slm/on-behalf';
import { providePermission } from '@slm/permission';
import { API_CONFIG, AppType, createApiConfig, createHostApp, HOST_APP } from '@slm/shared/environment';
import { provideUserState } from '@slm/user/state';
import { authInitializer, AuthModule, storageFactory } from '@solocal-manager/auth';

import {
    AvailablePublishersEffects,
    CustomRouterStateSerializer,
    GalleryEffects,
    HabilitatedLocationListEffects,
    HeadersInterceptor,
    libReducers,
    LoadingInterceptor,
    LocationEffects,
    MessagesInterceptor,
    metaReducers,
    PagesEffects,
    PublisherEffects,
    PublisherSearchEffects,
    PublisherStatisticsEffects,
    reducers,
} from '@solocal-manager/sirius/core/core';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CookieService } from 'ngx-cookie-service';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule, createAuthConfig, WpmLibModule, WpmSharedModule } from './core';
import { backofficePermissionConfig } from './core/config/backoffice-permission.config';
import { AdminCvivPageComponent } from './core/pages/admin-cviv-page/admin-cviv-page.component';
import { AdminEpjPageComponent } from './core/pages/admin-epj-page/admin-epj-page.component';
import { AdminPageComponent } from './core/pages/admin-page/admin-page.component';
import { AdminSearchPageComponent } from './core/pages/admin-search-page/admin-search-page.component';
import { AdminToolsIndexPageComponent } from './core/pages/admin-tools-index-page/admin-tools-index-page.component';
import { AdminToolsPageComponent } from './core/pages/admin-tools-page/admin-tools-page.component';
import { AdminToolsStatisticsPageComponent } from './core/pages/admin-tools-statistics-page/admin-tools-statistics-page.component';
import { WpmPartialsModule } from './core/partials/wpm-partials.module';
import { KpiModule } from './feature-kpi/kpi.module';

function createTranslateLoader(http: HttpClient): TranslateLoader {
    return new TranslatePoHttpLoader(http, './assets/i18n', '.po');
}

interface LoggerSettings {
    level: NgxLoggerLevel;
    serverLogLevel: NgxLoggerLevel;
}

const loggerSettings = {
    production: {
        level: NgxLoggerLevel.ERROR,
        serverLogLevel: NgxLoggerLevel.ERROR,
    },
    default: {
        level: NgxLoggerLevel.DEBUG,
        serverLogLevel: NgxLoggerLevel.ERROR,
    },
} satisfies Record<string, LoggerSettings>;

function createLoggerConfig() {
    return environment.production === true ? loggerSettings.production : loggerSettings.default;
}

@NgModule({
    declarations: [
        AppComponent,
        AdminPageComponent,
        AdminCvivPageComponent,
        AdminEpjPageComponent,
        AdminSearchPageComponent,
        AdminToolsPageComponent,
        AdminToolsIndexPageComponent,
        AdminToolsStatisticsPageComponent,
    ],
    imports: [
        LoggerModule.forRoot(createLoggerConfig()),
        WpmPartialsModule,
        BrowserModule,
        HttpClientModule,
        CommonModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        KpiModule,
        StoreModule.forRoot(
            { ...reducers, ...libReducers, ...routerReducer },
            {
                metaReducers,
                runtimeChecks: {
                    strictStateImmutability: false,
                    strictActionImmutability: false,
                },
            },
        ),
        !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 2000 }) : [],
        StoreRouterConnectingModule.forRoot(),
        EffectsModule.forRoot([
            AvailablePublishersEffects,
            LocationEffects,
            PublisherEffects,
            PublisherSearchEffects,
            GalleryEffects,
            PublisherStatisticsEffects,
            PagesEffects,
            HabilitatedLocationListEffects,
        ]),
        EpjSearchModule,

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        WpmLibModule,
        WpmSharedModule,
        ModalModule.forRoot(),
        ProgressbarModule.forRoot(),
        TooltipModule.forRoot(),
        BsDropdownModule.forRoot(),
        PaginationModule.forRoot(),
        TabsModule.forRoot(),
        AlertModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TypeaheadModule.forRoot(),
        CoreModule.forRoot(),
        AuthModule,
    ],
    exports: [],
    providers: [
        provideAnalytics(),
        provideLocationState(),
        provideOnBehalf(),
        providePermission(backofficePermissionConfig),
        provideUserState(),
        authInitializer(createAuthConfig(environment.keycloakUrl)),
        { provide: OAuthStorage, useFactory: storageFactory },
        {
            provide: HOST_APP,
            useValue: createHostApp(AppType.SlmBackoffice, environment),
        },
        {
            provide: API_CONFIG,
            useValue: createApiConfig(environment),
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MessagesInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeadersInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true,
        },
        {
            provide: RouterStateSerializer,
            useClass: CustomRouterStateSerializer,
        },
        CookieService,
        { provide: TagManagerService, useClass: TagManagerMockService },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
