import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiErrorActions } from '@slm/shared/error';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PaymentMethodsService } from '../services';
import { PaymentMethodsActions } from './payment-methods.actions';

export const PaymentMethodsEffects = {
    get$: createEffect(
        (actions$ = inject(Actions), paymentMethodsService = inject(PaymentMethodsService)) =>
            actions$.pipe(
                ofType(PaymentMethodsActions.get),
                switchMap(() =>
                    paymentMethodsService.getPaymentMethods().pipe(
                        map(paymentMethods => PaymentMethodsActions.getSuccess({ paymentMethods })),
                        catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                    ),
                ),
            ),
        { functional: true },
    ),
};
