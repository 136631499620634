import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiErrorActions } from '@slm/shared/error';
import { of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { EpjSearchService } from '../services';
import { EpjSearchActions } from './epj-search.actions';

export const EpjSearchEffects = {
    get$: createEffect(
        (actions$ = inject(Actions), epjSearchService = inject(EpjSearchService)) =>
            actions$.pipe(
                ofType(EpjSearchActions.get),
                debounceTime(400),
                switchMap(({ payload }) =>
                    epjSearchService.getEpjSearch(payload).pipe(
                        map(result => EpjSearchActions.getSuccess({ payload: result })),
                        catchError(error => of(new ApiErrorActions.CatchApiError(error))),
                    ),
                ),
            ),
        { functional: true },
    ),
};
