import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { HabilitatedLocation } from '@slm/habilitated-locations';
import { ApiErrorActions } from '@slm/shared/error';
import {
    GetHabilitatedLocationList,
    GetLocationList,
    selectHabilitatedLocationList,
} from '@solocal-manager/sirius/core/core';
import { UserSettings } from '@solocal-manager/sirius/core/models';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'slm-location-list-search',
    templateUrl: './location-list-search.component.html',
    styleUrls: ['./location-list-search.component.scss'],
})
export class LocationListSearchComponent implements OnInit, OnChanges, OnDestroy {
    @Input() settings: UserSettings = { itemsPerPage: 20, currentPage: 1 };
    @Input() resetSearch: Subject<void> = new Subject();
    @Output() search: EventEmitter<{ searchValue: string; settings: UserSettings; count?: number }> =
        new EventEmitter();

    searchForm: UntypedFormGroup;
    private isDestroyed$: Subject<void> = new Subject();
    private isLoading = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private store: Store<any>,
        private actions$: Actions,
    ) {}

    get searchValue(): string {
        return this.searchForm ? this.searchForm.get('email').value : '';
    }

    get canSubmit(): boolean {
        return (this.searchForm && this.searchForm.valid) || !this.searchValue;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.settings) {
            this.getHabilitatedLocationsList();
        }
    }

    ngOnInit(): void {
        this.searchForm = this.formBuilder.group({
            email: ['', Validators.email],
        });

        this.listenHabilitatedLocationsList();
        this.listenApiErrors();
        this.resetSearch.pipe(takeUntil(this.isDestroyed$)).subscribe(() => this.searchForm.setValue({ email: '' }));
    }

    ngOnDestroy(): void {
        this.isDestroyed$.next(undefined);
        this.isDestroyed$.complete();
    }

    getLocationsList(habilitatedLocationList: HabilitatedLocation[]): void {
        if (habilitatedLocationList.length) {
            this.store.dispatch(
                new GetLocationList({
                    companyId: 'all',
                    config: {
                        pageNumber: this.settings.currentPage,
                        pageSize: this.settings.itemsPerPage,
                        shouldReturnMeta: true,
                        partnerIds: habilitatedLocationList.map(hLoc => hLoc.epj),
                        pageOnly: true,
                    },
                }),
            );
        }
    }

    submitSearch(): void {
        this.settings.currentPage = 1;
        this.search.emit({
            searchValue: this.searchValue,
            settings: this.settings,
        });

        if (this.searchValue) {
            this.getHabilitatedLocationsList();
        }
    }

    getHabilitatedLocationsList(): void {
        if (this.searchValue && !this.isLoading) {
            this.isLoading = true;
            this.store.dispatch(
                new GetHabilitatedLocationList({
                    account: this.searchValue,
                    limit: this.settings.itemsPerPage,
                    offset: this.settings.currentPage,
                }),
            );
        }
    }

    private listenHabilitatedLocationsList(): void {
        this.store
            .pipe(
                select(selectHabilitatedLocationList),
                takeUntil(this.isDestroyed$),
                tap(res => {
                    if (res && this.searchValue) {
                        this.search.emit({
                            searchValue: this.searchValue,
                            settings: this.settings,
                            count: res.count,
                        });
                    }
                }),
                map(res => (res ? res.data : [])),
            )
            .subscribe(habilitatedLocationList => {
                this.isLoading = false;
                this.getLocationsList(habilitatedLocationList);
            });
    }

    private listenApiErrors(): void {
        this.actions$
            .pipe(
                ofType<ApiErrorActions.CatchApiError>(ApiErrorActions.ApiErrorActionTypes.CATCH_API_ERROR),
                takeUntil(this.isDestroyed$),
                map(action => action.payload),
            )
            .subscribe(error => {
                if ('getHabilitatedLocationList' in error || 'getHabilitatedLocationListEmpty' in error) {
                    this.isLoading = false;
                }
            });
    }
}
