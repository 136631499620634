import { Action } from '@ngrx/store';
import { HabilitatedLocation, IHabilitatedLocationListParams } from '@slm/habilitated-locations';
import { PaginationDTO } from '@solocal-manager/sirius/support/base-models';

export enum SearchHabilitatedLocationsActionTypes {
    SEARCH_HAB_LOCATION_LIST = '[HAB_LOCATION_LIST] search',
    SEARCH_HAB_LOCATION_LIST_SUCCESS = '[HAB_LOCATION_LIST] search success',
    RESET_SEARCH_HAB_LOCATION_LIST = '[HAB_LOCATION_LIST] reset search',
}

export class SearchHabilitatedLocations implements Action {
    readonly type = SearchHabilitatedLocationsActionTypes.SEARCH_HAB_LOCATION_LIST;

    constructor(public payload: IHabilitatedLocationListParams) {}
}

export class SearchHabilitatedLocationsSuccess implements Action {
    readonly type = SearchHabilitatedLocationsActionTypes.SEARCH_HAB_LOCATION_LIST_SUCCESS;

    constructor(public payload: PaginationDTO<HabilitatedLocation>) {}
}

export class ResetSearchHabilitatedLocations implements Action {
    readonly type = SearchHabilitatedLocationsActionTypes.RESET_SEARCH_HAB_LOCATION_LIST;
}

export type SearchHabilitatedLocationsActionsUnion =
    | SearchHabilitatedLocations
    | SearchHabilitatedLocationsSuccess
    | ResetSearchHabilitatedLocations;
