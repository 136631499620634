import { createFeature, createReducer, on } from '@ngrx/store';
import { EpjSearchActions } from './epj-search.actions';

const EPJ_SEARCH_FEATURE_KEY = 'epjSearch';

const initialState = [];

export const EpjSearchFeature = createFeature({
    name: EPJ_SEARCH_FEATURE_KEY,
    reducer: createReducer(
        initialState,

        on(EpjSearchActions.getSuccess, (_, { payload }) => payload),
    ),
});
