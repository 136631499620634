<div class="row">
    <div class="col-md-12">
        <form [formGroup]="form" novalidate>
            <div class="form-group">
                <label for="partnerIdSearch">{{ 'Enter EPJ' | translate }}</label>
                <input
                    [(ngModel)]="userInput"
                    autocomplete="off"
                    id="partnerIdSearch"
                    class="form-control"
                    formControlName="searchstring"
                />
            </div>
        </form>
    </div>
</div>
<div>
    <slm-search-epj-results [userInput]="userInput"></slm-search-epj-results>
</div>
