import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
    HabilitatedLocation,
    HabilitatedLocationsService,
    IHabilitatedLocationListParams,
} from '@slm/habilitated-locations';
import { HOST_APP } from '@slm/shared/environment';
import { ApiErrorActions } from '@slm/shared/error';
import { PaginationDecoratorDTO } from '@solocal-manager/sirius/support/base-models';
import { get } from 'lodash-es';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
    GetHabilitatedLocationListSuccess,
    HabilitatedLocationListActionTypes,
} from '../actions/habilitated-location-list.actions';

@Injectable({ providedIn: 'root' })
export class HabilitatedLocationListEffects {
    private readonly hostApp = inject(HOST_APP);
    getLocationList$ = createEffect(() =>
        this.update$.pipe(
            ofType(HabilitatedLocationListActionTypes.GET_HAB_LOCATION_LIST),
            switchMap((payload: IHabilitatedLocationListParams) => {
                return this.hLocationListService.getLocationList(payload).pipe(
                    map(res => (this.hostApp.isSlmWeb ? res : this.removePrehabilitatedLocations(res))),
                    map(res => this.handleApiResponse(res)),
                    catchError(err => {
                        this.logger.debug('HabilitatedLocationListEffects.getLocationList$ error ', err);
                        return of(
                            new ApiErrorActions.CatchApiError({
                                souce: 'getHabilitatedLocationList',
                                getHabilitatedLocationList: err,
                            }),
                        );
                    }),
                );
            }),
        ),
    );

    constructor(
        private update$: Actions,
        private hLocationListService: HabilitatedLocationsService,
        private logger: NGXLogger,
    ) {}

    /**
     * Dispatch error if use isn't habilitated on any EPJ
     * #13744 spec 1)
     * @param result API result
     */
    private handleApiResponse(result: PaginationDecoratorDTO<HabilitatedLocation>): Action {
        const hLocationList = get(result, 'data', []);
        return hLocationList && hLocationList.length
            ? new GetHabilitatedLocationListSuccess(result)
            : new ApiErrorActions.CatchApiError({
                  source: 'getHabilitatedLocationListEmpty',
                  getHabilitatedLocationListEmpty: 'empty',
              });
    }

    private removePrehabilitatedLocations(
        res: PaginationDecoratorDTO<HabilitatedLocation>,
    ): PaginationDecoratorDTO<HabilitatedLocation> {
        return {
            ...res,
            data: res.data.filter(habLocation => habLocation.status !== 'PRE_HABILITATED'),
        };
    }
}
