<div class="row" *ngIf="results$ | async as results">
    <div class="col-md-2">
        <a *ngIf="showReload" (click)="loadResults()" class="btn btn-default col-md-12">{{
            'refresh list' | translate
        }}</a>
        <div class="scroll-area">
            <ul *ngIf="results && results.length > 0" class="list-group">
                <li
                    class="list-group-item"
                    [ngClass]="{ active: currentId === row.id }"
                    (click)="setCurrentLocation(row)"
                    *ngFor="let row of results"
                >
                    <strong class="bold">{{ row.name }}</strong>
                </li>
            </ul>
        </div>
    </div>
    <div class="col-md-2">
        <span *ngIf="results && results.length === 0">{{ 'no results' | translate }}</span>
        <span *ngIf="!results">{{ 'loading ...' | translate }}</span>
    </div>
    <div class="col-md-10">
        <h4 *ngIf="currentLocation">{{ row.name }}</h4>
        <p>{{ uiUrl }}</p>
        <iframe class="framed" *ngIf="uiUrl" title="url" [src]="uiUrl | safeUrl"></iframe>
    </div>
</div>
