import { Component } from '@angular/core';
import { SearchResultsComponent } from './search-results.component';

@Component({
    selector: 'slm-search-cviv-results',
    templateUrl: './search-results.component.html',
    styleUrls: ['./search-results.component.scss'],
})
export class SearchCvivResultsComponent extends SearchResultsComponent {
    public showReload = true;

    constructor() {
        super();
        this.loadResults();
    }

    loadResults() {
        this.epjSearchFacade.get({ cviv_todo: true });
    }
}
