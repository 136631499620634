import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EpjSearchEffects } from './+state/epj-search.effects';
import { EpjSearchFeature } from './+state/epj-search.feature';
import { EpjSearchFacade } from './public_api';
import { EpjSearchService } from './services';

@NgModule({
    imports: [StoreModule.forFeature(EpjSearchFeature), EffectsModule.forFeature(EpjSearchEffects)],
    providers: [EpjSearchFacade, EpjSearchService],
})
export class EpjSearchModule {}
