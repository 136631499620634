import { Directive, inject } from '@angular/core';
import { EpjSearchFacade } from '@slm/epj-search';
import { environment } from '../../../../environments/environment';

@Directive()
export class SearchResultsComponent {
    protected readonly epjSearchFacade = inject(EpjSearchFacade);

    readonly results$ = this.epjSearchFacade.data$;

    uiUrl: string;
    searchType: string;
    currentLocation: any;
    currentId: number;

    setCurrentLocation(result: any): void {
        this.currentId = result.id;
        this.currentLocation = result.address;
        this.uiUrl = this._createUiUrl(result);
    }

    private _createUiUrl(result: any): string {
        let url = environment.uiUrl + `?for_customer=${result.customer_id}`;

        const params = {
            company_id: result.id,
        };

        Object.keys(params).forEach(key => {
            url += `&${key}=${params[key]}`;
        });

        return url;
    }
}
