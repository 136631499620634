import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { EpjSearchActions } from './epj-search.actions';
import { EpjSearchFeature } from './epj-search.feature';

@Injectable()
export class EpjSearchFacade {
    private readonly store = inject(Store);

    readonly data$ = this.store.select(EpjSearchFeature.selectEpjSearchState);

    get(payload: any): void {
        this.store.dispatch(EpjSearchActions.get({ payload }));
    }
}
