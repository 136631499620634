import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentMethodsModule } from '@slm/payment-methods';
import { SafeUrlPipe } from '@slm/shared/html';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { WpmLibModule } from '../wpm-lib.module';
import { WpmSharedModule } from '../wpm-shared.module';
import { CreateLocationComponent } from './create-location/create-location.component';
import { FacebookConnectTutorialComponent } from './facebook-connect-tutorial/facebook-connect-tutorial.component';
import { FeedbackMessageHandlerComponent } from './feedback-message-handler/feedback-message-handler.component';
import { InputAddressComponent } from './form/input-address/input-address.component';
import { InputCategoryComponent } from './form/input-category/input-category.component';
import { InputCategoryPublisherComponent } from './form/input-category-publisher/input-category-publisher.component';
import { InputCheckboxComponent } from './form/input-checkbox/input-checkbox.component';
import { InputCombinedUrlsComponent } from './form/input-combined-urls/input-combined-urls.component';
import { InputDatepickerComponent } from './form/input-datepicker/input-datepicker.component';
import { InputEmailComponent } from './form/input-email/input-email.component';
import { InputErrorMessagesComponent } from './form/input-error-messages/input-error-messages.component';
import { InputGoogleMapsUrlComponent } from './form/input-google-maps-url/input-google-maps-url.component';
import { InputImageComponent } from './form/input-image/input-image.component';
import { InputImageUploadComponent } from './form/input-image-upload/input-image-upload.component';
import { InputMultiSelectComponent } from './form/input-multi-select/input-multi-select.component';
import { InputOpeningHoursComponent } from './form/input-opening-hours/input-opening-hours.component';
import { InputPaymentMethodsComponent } from './form/input-payment-methods/input-payment-methods.component';
import { InputPhonesComponent } from './form/input-phones/input-phones.component';
import { InputSearchPublisherComponent } from './form/input-search-publisher/input-search-publisher.component';
import { InputServiceAreaComponent } from './form/input-service-area/input-service-area.component';
import { InputSpecificOpeningHoursComponent } from './form/input-specific-opening-hours/input-specific-opening-hours.component';
import { InputTextMultiComponent } from './form/input-text-multi/input-text-multi.component';
import { InputUrlComponent } from './form/input-url/input-url.component';
import { InputVideoComponent } from './form/input-video/input-video.component';
import { HeaderComponent } from './header/header.component';
import { ImageEditorComponent } from './image-editor/image-editor.component';
import { ImageListComponent } from './image-list/image-list.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { LocationEditComponent } from './location-edit/location-edit.component';
import { LocationListComponent } from './location-list/location-list.component';
import { LocationListItemComponent } from './location-list-item/location-list-item.component';
import { LocationListSearchComponent } from './location-list-search/location-list-search.component';
import { LocationsMapComponent } from './locations-map/locations-map.component';
import { MenuComponent } from './menu/menu.component';
import { MultiSearchComponent } from './multi-search/multi-search.component';
import { OnBehalfComponent } from './onbehalf/on-behalf.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ProductionInformationComponent } from './production-information/production-information.component';
import { PublisherConnectComponent } from './publisher-connect/publisher-connect.component';
import { PublisherConnectFacebookComponent } from './publisher-connect-facebook/publisher-connect-facebook.component';
import { PublisherConnectGmbNewComponent } from './publisher-connect-gmb-new/publisher-connect-gmb-new.component';
import { PublisherConnectGmbProcessComponent } from './publisher-connect-gmb-process/publisher-connect-gmb-process.component';
import { PublisherConnectGmbSearchComponent } from './publisher-connect-gmb-search/publisher-connect-gmb-search.component';
import { PublisherConnectPagesjaunesComponent } from './publisher-connect-pagesjaunes/publisher-connect-pagesjaunes.component';
import { PublisherConnectStatusComponent } from './publisher-connect-status/publisher-connect-status.component';
import { PublisherConnectTripadvisorComponent } from './publisher-connect-tripadvisor/publisher-connect-tripadvisor.component';
import { PublisherConnectWebsiteComponent } from './publisher-connect-website/publisher-connect-website.component';
import { PublisherStatusListComponent } from './publisher-status-list/publisher-status-list.component';
import { PublisherStatusListItemComponent } from './publisher-status-list-item/publisher-status-list-item.component';
import { SearchComponent } from './search/search.component';
import { SearchCvivResultsComponent } from './search-results/search-cviv-results.component';
import { SearchEpjResultsComponent } from './search-results/search-epj-results.component';
import { SubmitButtonComponent } from './submit-button/submit-button.component';
import { SupportPlatformsComponent } from './support-platforms/support-platforms.component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        RouterModule,
        WpmSharedModule,
        WpmLibModule,
        ModalModule.forRoot(),
        ProgressbarModule.forRoot(),
        TooltipModule.forRoot(),
        BsDropdownModule.forRoot(),
        PaginationModule.forRoot(),
        AlertModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TypeaheadModule.forRoot(),
        CarouselModule.forRoot(),
        TranslateModule,
        DragDropModule,
        SafeUrlPipe,
        PaymentMethodsModule,
    ],
    declarations: [
        HeaderComponent,
        MenuComponent,
        SearchComponent,
        SearchEpjResultsComponent,
        SearchCvivResultsComponent,
        LocationListComponent,
        LocationListItemComponent,
        LocationEditComponent,
        PublisherStatusListComponent,
        PublisherStatusListItemComponent,
        PublisherConnectComponent,
        ImageEditorComponent,
        LoadingIndicatorComponent,
        MultiSearchComponent,
        FeedbackMessageHandlerComponent,
        ImageEditorComponent,
        SupportPlatformsComponent,
        OnBehalfComponent,
        PublisherConnectWebsiteComponent,
        PublisherConnectTripadvisorComponent,
        PublisherConnectPagesjaunesComponent,
        ToggleSwitchComponent,
        PaginationComponent,
        SubmitButtonComponent,
        FacebookConnectTutorialComponent,
        ProductionInformationComponent,
        ImageListComponent,
        PublisherConnectGmbNewComponent,
        PublisherConnectGmbSearchComponent,
        PublisherConnectFacebookComponent,
        PublisherConnectStatusComponent,
        InputErrorMessagesComponent,
        InputAddressComponent,
        InputEmailComponent,
        InputUrlComponent,
        InputTextMultiComponent,
        InputCheckboxComponent,
        InputMultiSelectComponent,
        InputImageUploadComponent,
        InputImageComponent,
        InputDatepickerComponent,
        InputCategoryComponent,
        InputCategoryPublisherComponent,
        InputVideoComponent,
        InputSearchPublisherComponent,
        InputServiceAreaComponent,
        InputPhonesComponent,
        InputPaymentMethodsComponent,
        InputGoogleMapsUrlComponent,
        InputSpecificOpeningHoursComponent,
        InputOpeningHoursComponent,
        InputCombinedUrlsComponent,
        PublisherConnectGmbProcessComponent,
        CreateLocationComponent,
        LocationListSearchComponent,
        LocationsMapComponent,
    ],
    exports: [
        HeaderComponent,
        MenuComponent,
        SearchComponent,
        SearchEpjResultsComponent,
        SearchCvivResultsComponent,
        LocationListComponent,
        LocationListItemComponent,
        LocationEditComponent,
        PublisherStatusListComponent,
        PublisherStatusListItemComponent,
        PublisherConnectComponent,
        ImageEditorComponent,
        LoadingIndicatorComponent,
        MultiSearchComponent,
        FeedbackMessageHandlerComponent,
        ImageEditorComponent,
        SupportPlatformsComponent,
        OnBehalfComponent,
        PublisherConnectWebsiteComponent,
        PublisherConnectTripadvisorComponent,
        PublisherConnectPagesjaunesComponent,
        ToggleSwitchComponent,
        PaginationComponent,
        SubmitButtonComponent,
        FacebookConnectTutorialComponent,
        ProductionInformationComponent,
        PublisherConnectGmbNewComponent,
        PublisherConnectGmbSearchComponent,
        PublisherConnectFacebookComponent,
        CreateLocationComponent,
        LocationListSearchComponent,
        LocationsMapComponent,
    ],
})
export class WpmPartialsModule {}
