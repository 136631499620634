import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from '@slm/shared/environment';
import { Location, PaginationDecoratorDTO } from '@solocal-manager/sirius/support/base-models';
import { get } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HabilitatedLocation, IHabilitatedLocationListParams } from '../../+state/habilitated-locations.models';

@Injectable()
export class HabilitatedLocationsService {
    public readonly httpClient = inject(HttpClient);
    public readonly envService = inject(EnvironmentService);

    getLocationList(options: IHabilitatedLocationListParams): Observable<PaginationDecoratorDTO<HabilitatedLocation>> {
        let params = new HttpParams();
        const userId = get(options, 'account') ? 'all' : 'me';

        for (const key in options) {
            if (key in options && !!options[key]) {
                params = params.append(key, options[key].toString());
            }
        }
        const url = `${this.envService.apiUrl}/sirius/user/${userId}/authorized-epjs`;
        return this.httpClient.get<PaginationDecoratorDTO<HabilitatedLocation>>(url, { params }).pipe(
            map(result => {
                result.currentPage = get(result, 'current_page');
                return result;
            }),
        );
    }

    getDefaultLocation(): Observable<{ epj: string }> {
        const url = `${this.envService.apiUrl}/sirius/user/me/default-epj`;
        return this.httpClient.get<{ data: { epj: string } }>(url).pipe(map(res => (res ? res.data : null)));
    }

    /**
     * It creates the location if not known in sirius.
     */
    setSelectedLocation(locationEpj: string): Observable<Location> {
        const url = `${this.envService.apiUrl}/sirius/user/me/select-epj`;
        return this.httpClient
            .post<{ data: Location }>(url, { epj: locationEpj })
            .pipe(map(res => (res ? res.data : null)));
    }

    deleteLocation(params: { epj: string }): Observable<any> {
        const url = `${this.envService.apiUrl}/sirius/user/me/delete-authorized-epj`;
        return this.httpClient.post<{ data: any }>(url, params);
    }

    deleteIndirectLocation(params: { epj: string; ignored: boolean }): Observable<any> {
        const url = `${this.envService.apiUrl}/sirius/user/me/user-ignore-epj`;
        return this.httpClient.post<{ data: any }>(url, params);
    }
}
