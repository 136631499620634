import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SearchResultsComponent } from './search-results.component';

@Component({
    selector: 'slm-search-epj-results',
    templateUrl: './search-results.component.html',
    styleUrls: ['./search-results.component.scss'],
})
export class SearchEpjResultsComponent extends SearchResultsComponent implements OnChanges {
    @Input() userInput;
    private searchString: string;
    public showReload = false;

    ngOnChanges(changes: SimpleChanges): void {
        this.searchString = changes.userInput.currentValue;
    }

    loadResults(): void {
        if (this.searchString) {
            this.epjSearchFacade.get({ partner_id_extended: this.searchString });
        }
    }
}
