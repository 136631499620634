import { Component, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EpjSearchFacade } from '@slm/epj-search';

@Component({
    selector: 'slm-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
    private readonly epjSearchFacade = inject(EpjSearchFacade);

    public form: UntypedFormGroup;
    public userInput: string;

    constructor(private formBuilder: UntypedFormBuilder) {
        this.userInput = '5e857a67-aed7-4d9e-ba18-bb3f5fa01058';

        this.form = this.formBuilder.group({
            searchstring: [''],
        });

        this.form.valueChanges.subscribe(controls => {
            if (controls.searchstring) {
                this.search(controls.searchstring);
            }
        });
    }

    private search(searchstring: string): void {
        this.epjSearchFacade.get({ partner_id_extended: searchstring });
    }
}
