import { Component, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserFacade } from '@slm/user/state';
import { environment } from '../environments/environment';

@Component({
    selector: 'slm-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    private readonly translateService = inject(TranslateService);
    private readonly userFacade = inject(UserFacade);

    constructor() {
        const defaultLanguage = environment.defaultLanguage;
        const availableLanguages = ['en', 'fr'];
        const browserLang = this.translateService.getBrowserLang();

        this.translateService.addLangs(availableLanguages);

        const currentLang = availableLanguages.includes(browserLang) ? browserLang : defaultLanguage;

        // Default language 'en' required to make empty english translations work
        this.translateService.setDefaultLang('en');
        this.translateService.use(currentLang);
    }

    ngOnInit(): void {
        this.userFacade.fetchUser();
    }
}
