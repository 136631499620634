import { createFeature, createReducer, on } from '@ngrx/store';
import { PaymentMethodsActions } from './payment-methods.actions';
import { PaymentMethod } from './payment-methods.models';

const PAYMENT_METHODS_FEATURE_KEY = 'paymentMethods';

type PaymentMethodsState = PaymentMethod[];

const initialState: PaymentMethodsState = [];

export const PaymentMethodsFeature = createFeature({
    name: PAYMENT_METHODS_FEATURE_KEY,
    reducer: createReducer(
        initialState,

        on(PaymentMethodsActions.getSuccess, (_, { paymentMethods }) => ({ ...paymentMethods })),
    ),
});
