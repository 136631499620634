import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { PaymentMethodsActions } from './payment-methods.actions';
import { PaymentMethodsFeature } from './payment-methods.feature';

@Injectable()
export class PaymentMethodsFacade {
    private readonly store = inject(Store);

    readonly paymentMethods$ = this.store.select(PaymentMethodsFeature.selectPaymentMethodsState);

    get(): void {
        this.store.dispatch(PaymentMethodsActions.get());
    }
}
