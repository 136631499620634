import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PaymentMethods } from './payment-methods.models';

export const PaymentMethodsActions = createActionGroup({
    source: 'Payment Methods',
    events: {
        get: emptyProps(),
        getSuccess: props<{ paymentMethods: PaymentMethods }>(),
    },
});
